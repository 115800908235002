const { auth } = require("./firebase-config"); // CommonJS import for firebase-config
const { signInWithEmailAndPassword } = require("firebase/auth"); // CommonJS import for Firebase Auth
require("leaflet/dist/leaflet.css"); // Import Leaflet CSS
const L = require("leaflet"); // Import the Leaflet library using CommonJS
require("./draw-map.js");  // Import draw-map.js so it gets bundled
require("./css/styles.css"); // Ensure this line is present to import the CSS

// Initialize tooltips and other components
document.addEventListener("DOMContentLoaded", () => {
    // Initialize tooltips
    var tooltipTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle=\"tooltip\"]"));
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
        new bootstrap.Tooltip(tooltipTriggerEl, {
            template: "<div class=\"tooltip tooltip-custom\" role=\"tooltip\"><div class=\"arrow\"></div><div class=\"tooltip-inner\"></div></div>"
        });
    });

    setupLogout();  // Attach event listeners for logout
    setupModal();   // Attach event listeners for About modal
});

// Handle login functionality
document.addEventListener("DOMContentLoaded", () => {
    const loginForm = document.getElementById("login-form");

    if (loginForm) {
        loginForm.addEventListener("submit", (event) => {
            event.preventDefault(); // Prevent the form from submitting the default way

            const email = document.getElementById("email").value;
            const password = document.getElementById("password").value;
            const errorMessage = document.getElementById("auth-error-message");

            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    console.log("User logged in:", userCredential.user);
                    window.location.replace("./index.html"); // Replaces the URL in history without adding a new entry
                })
                .catch((error) => {
                    console.error("Login failed:", error);
                    errorMessage.textContent = "Login failed: " + error.message; // Display error message
                });
        });
    }
});

// Handle authentication state
function handleAuthState() {
    auth.onAuthStateChanged(user => {
        const mainContent = document.getElementById("main-content");
        const isLoginPage = window.location.pathname.includes("login.html");  // Check if current page is login.html

        if (user) {
            console.log("User is logged in.");
            if (mainContent) {
                mainContent.style.display = "block";
            }
            if (isLoginPage) {
                window.location.replace("./index.html"); // Replaces the URL in history without adding a new entry
            }
        } else {
            console.log("User is not logged in.");
            if (!isLoginPage) {
                window.location.replace("./login.html"); // Replaces the URL in history without adding a new entry
            }
        }
    }, error => {
        console.error("Error checking auth state:", error);
    });
}

function setupLogout() {
    const logoutLink = document.getElementById("logout-link");
    if (logoutLink) {
        console.log("Logout link found, attaching event listener.");
        logoutLink.addEventListener("click", (event) => {
            event.preventDefault();
            auth.signOut()
                .then(() => {
                    console.log("User logged out.");
                    window.location.href = "./login.html"; // Redirect after logout
                })
                .catch((error) => {
                    console.error("Error logging out:", error);
                });
        });
    } else {
        console.error("Logout link not found.");
    }
}

function setupModal() {
    const modal = document.getElementById("aboutModal");
    const aboutLink = document.getElementById("about-link");
    const closeBtn = document.getElementsByClassName("close")[0];

    if (aboutLink && modal && closeBtn) {
        console.log("Modal elements found, attaching event listeners.");
        aboutLink.addEventListener("click", (event) => {
            event.preventDefault();
            modal.style.display = "block";
        });

        closeBtn.addEventListener("click", () => {
            modal.style.display = "none";
        });

        window.addEventListener("click", (event) => {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        });
    } else {
        console.error("Modal elements not found.");
    }
}

// Handle iframe button functionality
function setupIframeButtons() {
    const iframe = document.getElementById("main-iframe");
    const showDataButton = document.getElementById("show-data");
    const showMapButton = document.getElementById("show-map");
    
    const mapUrl = "#";  // Make sure to set the map URL properly
    const dataUrl = "https://airtable.com/embed/appKyd7VMJrkaokEr/shr2m8GKOYxUucCjP?viewControls=on";
    
    if (iframe && showDataButton && showMapButton) {
        showDataButton.onclick = function() {
            iframe.src = dataUrl;
            iframe.className = "airtable-data-embed";
            showDataButton.classList.add("active");
            showMapButton.classList.remove("active");
        };
        
        showMapButton.onclick = function() {
            iframe.src = mapUrl;
            iframe.className = "airtable-embed";
            showMapButton.classList.add("active");
            showDataButton.classList.remove("active");
        };
        
        showMapButton.classList.add("active"); // Set Show Map button as active
    }
}

// Initialize the app
function initApp() {
    handleAuthState();
    setupLogout();
    setupModal();
    setupIframeButtons();
}

initApp();
